<template>
	<div class="Documents-fixed-height">
		<v-col cols="12 pt-5">
			<v-flex class="d-flex border-bottom py-3">
				<div class="date py-1">
					<v-icon class="mr-2">mdi-calendar-month-outline</v-icon>
					<span class="h6" style="vertical-align: middle">Jan 23, 2022</span>
				</div>
				<v-spacer></v-spacer>
				<div>
					<v-tabs v-model="dateTab">
						<v-tab class="">Today</v-tab>
						<v-tab class="">Week</v-tab>
						<v-tab class="">Month</v-tab>
					</v-tabs>
				</div>
			</v-flex>
		</v-col>
		<v-col cols="12">
			<v-tabs-items v-model="dateTab">
				<v-tab-item>
					<div class="border py-3 px-2 __activity">
						<p class="mb-2 font-weight-500">
							There are 2 new tasks for you in “AirPlus Mobile App” project:
						</p>
						<v-flex class="d-flex align-items-center mb-5">
							<p class="m-0 mr-2 grey--text">Added at 4:23 PM by</p>
							<img src="https://picsum.photos/id/11/500/300" alt="" />
						</v-flex>
						<v-flex class="p-3 mt-3 border d-flex">
							<a href="#" class="font-weight-500 text-dark">Meeting width customer</a>
							<v-spacer></v-spacer>
							<div class="grey--text">Application design</div>
							<v-spacer></v-spacer>
							<div class="">
								<v-chip class="ma-0 font-weight-400 cm-primary-bg" color="primary" text-color="white">
									In progress
								</v-chip>
							</div>
						</v-flex>
						<v-flex class="p-3 mt-3 border d-flex">
							<a href="#" class="font-weight-500 text-dark">Meeting width customer</a>
							<v-spacer></v-spacer>
							<div class="grey--text">Application design</div>
							<v-spacer></v-spacer>
							<div class="">
								<v-chip class="m-0 font-weight-400" color="success"> Complete </v-chip>
							</div>
						</v-flex>
					</div>
					<div class="border py-3 px-2 __activity mt-3">
						<p class="mb-2 font-weight-500">3 New Incoming Project Files:</p>
						<v-flex class="d-flex align-items-center">
							<p class="m-0 mr-2 grey--text">Added at 4:23 PM by</p>
							<img src="https://picsum.photos/id/11/500/300" alt="" />
						</v-flex>
						<v-flex class="p-3 mt-3 border d-flex">
							<div class="d-flex align-items-center p-3">
								<inline-svg class="mr-2" :src="$assetURL('media/custom-svg/doc.svg')" />
								<div>
									<a href="#">
										<span class="font-weight-500 cm-primary">Customer guid</span><br />
										<span class="grey--text">2 mb</span>
									</a>
								</div>
							</div>
							<v-spacer></v-spacer>
							<div class="d-flex align-items-center p-3">
								<inline-svg class="mr-2" :src="$assetURL('media/custom-svg/pdf.svg')" />
								<div>
									<a href="#">
										<span class="font-weight-500 cm-primary">Customer guid</span><br />
										<span class="grey--text">2 mb</span>
									</a>
								</div>
							</div>
							<v-spacer></v-spacer>
							<div class="d-flex align-items-center p-3">
								<inline-svg class="mr-2" :src="$assetURL('media/custom-svg/css.svg')" />
								<div>
									<a href="#">
										<span class="font-weight-500 cm-primary">Customer guid</span><br />
										<span class="grey--text">2 mb</span>
									</a>
								</div>
							</div>
						</v-flex>
					</div>
					<div class="border py-3 px-2 __activity mt-3">
						<p class="mb-2 font-weight-500">
							Invitation for crafting engaging designs that speak human workshop
						</p>
						<v-flex class="d-flex align-items-center">
							<p class="m-0 mr-2 grey--text">Added at 4:23 PM by</p>
							<img src="https://picsum.photos/id/11/500/300" alt="" />
						</v-flex>
					</div>
				</v-tab-item>
				<v-tab-item>
					<div class="border py-3 px-2 __activity">
						<p class="mb-2 font-weight-500">
							There are 2 new tasks for you in “AirPlus Mobile App” project:
						</p>
						<v-flex class="d-flex align-items-center mb-5">
							<p class="m-0 mr-2 grey--text">Added at 4:23 PM by</p>
							<img src="https://picsum.photos/id/11/500/300" alt="" />
						</v-flex>
						<v-flex class="p-3 mt-3 border d-flex">
							<a href="#" class="font-weight-500 text-dark">Meeting width customer</a>
							<v-spacer></v-spacer>
							<div class="grey--text">Application design</div>
							<v-spacer></v-spacer>
							<div class="">
								<v-chip class="ma-0 font-weight-400 cm-primary-bg" color="primary" text-color="white">
									In progress
								</v-chip>
							</div>
						</v-flex>
						<v-flex class="p-3 mt-3 border d-flex">
							<a href="#" class="font-weight-500 text-dark">Meeting width customer</a>
							<v-spacer></v-spacer>
							<div class="grey--text">Application design</div>
							<v-spacer></v-spacer>
							<div class="">
								<v-chip class="m-0 font-weight-400" color="success"> Complete </v-chip>
							</div>
						</v-flex>
					</div>
					<div class="border py-3 px-2 __activity mt-3">
						<p class="mb-2 font-weight-500">3 New Incoming Project Files:</p>
						<v-flex class="d-flex align-items-center">
							<p class="m-0 mr-2 grey--text">Added at 4:23 PM by</p>
							<img src="https://picsum.photos/id/11/500/300" alt="" />
						</v-flex>
						<v-flex class="p-3 mt-3 border d-flex">
							<div class="d-flex align-items-center p-3">
								<inline-svg class="mr-2" :src="$assetURL('media/custom-svg/doc.svg')" />
								<div>
									<a href="#">
										<span class="font-weight-500 cm-primary">Customer guid</span><br />
										<span class="grey--text">2 mb</span>
									</a>
								</div>
							</div>
							<v-spacer></v-spacer>
							<div class="d-flex align-items-center p-3">
								<inline-svg class="mr-2" :src="$assetURL('media/custom-svg/pdf.svg')" />
								<div>
									<a href="#">
										<span class="font-weight-500 cm-primary">Customer guid</span><br />
										<span class="grey--text">2 mb</span>
									</a>
								</div>
							</div>
							<v-spacer></v-spacer>
							<div class="d-flex align-items-center p-3">
								<inline-svg class="mr-2" :src="$assetURL('media/custom-svg/css.svg')" />
								<div>
									<a href="#">
										<span class="font-weight-500 cm-primary">Customer guid</span><br />
										<span class="grey--text">2 mb</span>
									</a>
								</div>
							</div>
						</v-flex>
					</div>
					<div class="border py-3 px-2 __activity mt-3">
						<p class="mb-2 font-weight-500">
							Invitation for crafting engaging designs that speak human workshop
						</p>
						<v-flex class="d-flex align-items-center">
							<p class="m-0 mr-2 grey--text">Added at 4:23 PM by</p>
							<img src="https://picsum.photos/id/11/500/300" alt="" />
						</v-flex>
					</div>
				</v-tab-item>
				<v-tab-item>
					<div class="border py-3 px-2 __activity">
						<p class="mb-2 font-weight-500">
							There are 2 new tasks for you in “AirPlus Mobile App” project:
						</p>
						<v-flex class="d-flex align-items-center mb-5">
							<p class="m-0 mr-2 grey--text">Added at 4:23 PM by</p>
							<img src="https://picsum.photos/id/11/500/300" alt="" />
						</v-flex>
						<v-flex class="p-3 mt-3 border d-flex">
							<a href="#" class="font-weight-500 text-dark">Meeting width customer</a>
							<v-spacer></v-spacer>
							<div class="grey--text">Application design</div>
							<v-spacer></v-spacer>
							<div class="">
								<v-chip class="ma-0 font-weight-400 cm-primary-bg" color="primary" text-color="white">
									In progress
								</v-chip>
							</div>
						</v-flex>
						<v-flex class="p-3 mt-3 border d-flex">
							<a href="#" class="font-weight-500 text-dark">Meeting width customer</a>
							<v-spacer></v-spacer>
							<div class="grey--text">Application design</div>
							<v-spacer></v-spacer>
							<div class="">
								<v-chip class="m-0 font-weight-400" color="success"> Complete </v-chip>
							</div>
						</v-flex>
					</div>
					<div class="border py-3 px-2 __activity mt-3">
						<p class="mb-2 font-weight-500">3 New Incoming Project Files:</p>
						<v-flex class="d-flex align-items-center">
							<p class="m-0 mr-2 grey--text">Added at 4:23 PM by</p>
							<img src="https://picsum.photos/id/11/500/300" alt="" />
						</v-flex>
						<v-flex class="p-3 mt-3 border d-flex">
							<div class="d-flex align-items-center p-3">
								<inline-svg class="mr-2" :src="$assetURL('media/custom-svg/doc.svg')" />
								<div>
									<a href="#">
										<span class="font-weight-500 cm-primary">Customer guid</span><br />
										<span class="grey--text">2 mb</span>
									</a>
								</div>
							</div>
							<v-spacer></v-spacer>
							<div class="d-flex align-items-center p-3">
								<inline-svg class="mr-2" :src="$assetURL('media/custom-svg/pdf.svg')" />
								<div>
									<a href="#">
										<span class="font-weight-500 cm-primary">Customer guid</span><br />
										<span class="grey--text">2 mb</span>
									</a>
								</div>
							</div>
							<v-spacer></v-spacer>
							<div class="d-flex align-items-center p-3">
								<inline-svg class="mr-2" :src="$assetURL('media/custom-svg/css.svg')" />
								<div>
									<a href="#">
										<span class="font-weight-500 cm-primary">Customer guid</span><br />
										<span class="grey--text">2 mb</span>
									</a>
								</div>
							</div>
						</v-flex>
					</div>
					<div class="border py-3 px-2 __activity mt-3">
						<p class="mb-2 font-weight-500">
							Invitation for crafting engaging designs that speak human workshop
						</p>
						<v-flex class="d-flex align-items-center">
							<p class="m-0 mr-2 grey--text">Added at 4:23 PM by</p>
							<img src="https://picsum.photos/id/11/500/300" alt="" />
						</v-flex>
					</div>
				</v-tab-item>
			</v-tabs-items>
		</v-col>
	</div>
</template>
<script>
export default {
	name: "Activity",
	data() {
		return {
			dateTab: null,
		};
	},
};
</script>
<style></style>
