<template>
	<v-row>
		<v-col cols="11" class="mx-auto border-bottom user-profile-wrap">
			<v-row>
				<v-col cols="12" lg="6">
					<v-flex class="d-flex">
						<div class="card-rounded">
							<v-img
								lazy-src="https://picsum.photos/id/11/10/6"
								height="150"
								width="150"
								:src="profile_img"
								class="rounded-circle"
							></v-img>
						</div>
						<div class="d-flex justify-content-center flex-column pl-5">
							<div class="h4 pt-3" v-text="name"></div>
							<div class="d-flex flex-wrap font-weight-500 fs-6 mb-2 pe-2">
								<a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
									<v-icon class="text-gray-400 mr-1" style="font-size: 18px"> mdi-account </v-icon>
									Developer
								</a>
								<a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
									<v-icon class="text-gray-400 mr-1" style="font-size: 18px"> mdi-email </v-icon>
									{{ email }}
								</a>
							</div>
							<div class="font-weight-500 fs-6 pe-2">
								<div class="counter d-inline-block px-5 mr-2 mb-2">
									<v-icon class="text-gray-400 mr-1" style="font-size: 18px; color: #50cd89 !important">
										mdi-arrow-up
									</v-icon>
									<span class="num">4,500</span>
									<p class="">Files</p>
								</div>
								<div class="counter d-inline-block px-5">
									<v-icon class="text-gray-400 mr-1" style="font-size: 18px; color: #50cd89 !important">
										mdi-arrow-up
									</v-icon>
									<span class="num">4,500</span>
									<p class="">Files</p>
								</div>
							</div>
						</div>
					</v-flex>
				</v-col>
				<v-col cols="12" lg="6" class="d-flex">
					<v-row class="">
						<!-- <v-col cols="12" class="d-flex justify-content-end">
							<div class="pt-1">
								
							</div>
						</v-col> -->
						<v-col cols="12" class="d-flex justify-content-end align-items-center flex-wrap">
							<div class="width">
								<!-- <v-flex style="max-width:100%" class="">
									<div class="d-flex justify-content-between">
										<div class="grey--text font-weight-500 letter-spacing-initial">
											Profile completion
										</div>
										<div class="grey--text h6">
											70%
										</div>
									</div>
									<v-progress-linear
									color="teal"
									rounded
									value="70"
									height="6"
									></v-progress-linear>
								</v-flex>
								<v-flex style="max-width:100%" class="mt-8">
									<div class="d-flex justify-content-between">
										<div class="grey--text font-weight-500 letter-spacing-initial">
											Storage
										</div>
										<div class="grey--text h6">
											10%
										</div>
									</div>
									<v-progress-linear
									color="grey"
									rounded
									value="10"
									height="6"
									></v-progress-linear>
								</v-flex> -->

								<v-progress-circular :size="100" :width="12" :value="60" color="primary">
									<span class="text-center">
										Storage<br />
										60%
									</span>
								</v-progress-circular>
							</div>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="11" class="mx-auto mt-5 user-details-tab">
			<v-tabs v-model="userTab">
				<v-tab class="">Document</v-tab>
				<v-tab class="">Activity</v-tab>
				<v-tab class="">Permission</v-tab>
			</v-tabs>
			<v-tabs-items v-model="userTab">
				<v-tab-item>
					<Document></Document>
				</v-tab-item>
				<v-tab-item>
					<UserActivity></UserActivity>
				</v-tab-item>
				<v-tab-item>
					<Permission></Permission>
				</v-tab-item>
			</v-tabs-items>
		</v-col>
	</v-row>
</template>
<script>
import Document from "@/view/pages/userDetail/Document";
import UserActivity from "@/view/pages/userDetail/UserActivity";
import Permission from "@/view/pages/userDetail/Permissions";
export default {
	name: "UserDetail",
	data() {
		return {
			name: "",
			email: "",
			profile_img: "https://picsum.photos/id/11/500/300",
			userTab: null,
		};
	},
	components: {
		Document,
		UserActivity,
		Permission,
	},
};
</script>
<style></style>
