<template>
	<div class="Documents-fixed-height">
		<v-col cols="12">
			<div class="h4 pt-5 pb-5">My Documents</div>
			<v-row>
				<v-col lg="3" md="3" v-for="(item, index) in documents" :key="index" class="mb-3">
					<v-card elevation="0" class="d-flex flex-column align-items-center p-5 filecard">
						<inline-svg class="mr-2 mb-3" :src="item.icon" />
						<div class="file-name h6 py-1 fs-5">
							{{ item.name }}
						</div>
						<div class="number" v-if="item.folder">
							{{ item.innerfiles }}
						</div>
						<div class="number" v-else>
							{{ item.createDate }}
						</div>
					</v-card>
				</v-col>
			</v-row>
		</v-col>
	</div>
</template>
<script>
export default {
	name: "UserDocument",
	data() {
		return {
			documents: [
				{
					name: "Bthrust",
					folder: true,
					Owner: "me",
					innerfiles: "15 files",
					icon: "media/custom-svg/folder-document.svg",
				},
				{
					name: "Bthrust",
					folder: true,
					Owner: "me",
					innerfiles: "15 files",
					icon: "media/custom-svg/folder-document.svg",
				},
				{
					name: "Bthrust",
					folder: true,
					Owner: "me",
					innerfiles: "15 files",
					icon: "media/custom-svg/folder-document.svg",
				},
				{
					name: "Bthrust",
					folder: true,
					Owner: "me",
					innerfiles: "15 files",
					icon: "media/custom-svg/folder-document.svg",
				},
				{
					name: "Bthrust",
					folder: false,
					createDate: "3 day ago",
					icon: "media/custom-svg/doc.svg",
				},
				{
					name: "Bthrust",
					folder: false,
					createDate: "3 day ago",
					icon: "media/custom-svg/pdf.svg",
				},
				{
					name: "Bthrust",
					folder: false,
					createDate: "3 day ago",
					icon: "media/custom-svg/pdf.svg",
				},
				{
					name: "Bthrust",
					folder: false,
					createDate: "3 day ago",
					icon: "media/custom-svg/doc.svg",
				},
			],
		};
	},
};
</script>
<style>
.filecard:hover {
	cursor: pointer;
}
.filecard .file-name {
	color: #3f4254;
	font-weight: 600;
}
.filecard:hover .file-name {
	color: #0d47a1 !important;
}
.filecard .number {
	color: #b5b5c3;
	font-weight: 500;
}
.v-application .v-card:hover .v-icon {
	color: #9b9696 !important;
}
</style>
