<template>
	<div class="Documents-fixed-height Permissions-wrapper">
		<v-col cols="12">
			<div class="h4 pt-5 pb-5">Permissions</div>
			<div class="permission-table">
				<table class="border">
					<thead>
						<tr>
							<th></th>
							<th>Full Access</th>
							<th>View</th>
							<th>Create</th>
							<th>Edit</th>
							<th>Delete</th>
							<th>Approve</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Dashboard</td>
							<td width="120">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100"></td>
							<td width="100"></td>
							<td width="100"></td>
							<td width="100"></td>
						</tr>
						<tr>
							<td>Drive</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100"></td>
							<td width="100"></td>
							<td width="100"></td>
							<td width="100"></td>
						</tr>
						<tr>
							<td>Share with me</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100"></td>
							<td width="100"></td>
						</tr>
						<tr>
							<td>Recent</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100"></td>
							<td width="100"></td>
						</tr>
						<tr>
							<td>Starred</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100"></td>
							<td width="100"></td>
						</tr>
						<tr>
							<td>Bin</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
						</tr>
						<tr>
							<td>Users</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100">
								<v-checkbox dense hide-details color="success" v-model="checkbox"></v-checkbox>
							</td>
							<td width="100"></td>
						</tr>
					</tbody>
				</table>
			</div>
		</v-col>
	</div>
</template>
<script>
export default {
	name: "Permission",
	data() {
		return {
			showTabComponent: null,
			role: null,
			department: null,
			checkbox: true,
			allDepartment: ["Developer", "Desiner", "Quality"],
		};
	},
};
</script>
<style></style>
